<template>
    <!-- <pre>{{ $tera.project.prisma.prismatableDatabaseSearch.rows[0] }}</pre> -->
    <CustomTable :data="$tera.project.prisma.prismatableDatabaseSearch" :exportFileType="fileType"
        :formateddata="$tera.project.prisma.formatedDatabaseSearch" :isTable="styleTable" :showAdd="show"
        @update-cell="changed($event)" @add-column="addColumn($event)" @add-row="addRow($event)"
        @delete-row="deleteRow($event)" @delete-column="deleteColumn($event)" />
</template>


<script>
import CustomTable from 'iebh-custom-table/src/components/CustomTable';
export default {
    components: {
        CustomTable
    },
    props: {
        fileType: String
    },
    data() {
        return {
            show: true,
            styleTable: false,
        };
    },
    methods: {
        changed(event) {
            // console.log("Data Changed", event);
            this.$tera.project.prisma.prismatableDatabaseSearch.rows[event.rowIndex][event.cellIndex].label = event.updatedCell.label;
            const regex = /\(n=([^)]+)\)/g;
            let formattedText = event.updatedCell.label;
            formattedText = formattedText.replace(regex, (match, value) => {
                if (value === 'xxx') {
                    return `<span style="color: red;">${match}</span>`; // Apply red color for 'xxx'
                } else {
                    return `<span style="color: green;">${match}</span>`; // Apply green color for other values
                }
            });
            // console.log("format TERAAAAAAAAA", this.$tera.project.prisma.formatedDatabaseSearch, formattedText,this.$tera)
            this.$tera.project.prisma.formatedDatabaseSearch.rows[event.rowIndex][event.cellIndex].label = formattedText;
        },
        addColumn(event) {
            console.log("Col added", event);
            this.$tera.project.prisma.prismatableDatabaseSearch.headers.splice(event.index + 2, 0, event.columnName);
            this.$tera.project.prisma.prismatableDatabaseSearch.rows.forEach(row => row.splice(event.index + 2, 0, { label: ' ', addClass: [] }));
            this.$tera.project.prisma.formatedDatabaseSearch.headers.splice(event.index + 2, 0, event.columnName);
            this.$tera.project.prisma.formatedDatabaseSearch.rows.forEach(row => row.splice(event.index + 2, 0, { label: ' ', addClass: [] }));
        },
        addRow(event) {
            console.log("Row add", event);
            const newRow = [];
            const numColumns = this.$tera.project.prisma.prismatableDatabaseSearch.rows[0].length;
            for (let i = 0; i < numColumns; i++) {
                newRow.push({ label: ' ', addClass: [] });
            }
            this.$tera.project.prisma.prismatableDatabaseSearch.rows.splice(event.index + 1, 0, newRow);
            this.$tera.project.prisma.formatedDatabaseSearch.rows.splice(event.index + 1, 0, newRow);
        },
        deleteRow(event) {
            console.log("Delete Row", event);
            this.$tera.project.prisma.prismatableDatabaseSearch.rows.splice(event, 1);
            this.$tera.project.prisma.formatedDatabaseSearch.rows.splice(event, 1);

        },
        deleteColumn(event) {
            console.log("Delete Col", event);
            this.$tera.project.prisma.prismatableDatabaseSearch.headers.splice(event, 1);
            this.$tera.project.prisma.prismatableDatabaseSearch.rows.forEach(row => { row.addClass = []; row.splice(event, 1) });
            this.$tera.project.prisma.formatedDatabaseSearch.headers.splice(event, 1);
            this.$tera.project.prisma.formatedDatabaseSearch.rows.forEach(row => { row.addClass = []; row.splice(event, 1) });
        }
    }
};
</script>
