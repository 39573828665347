<template>
    <CustomTable :data="$tera.project.prisma.prismatableUpdateDatabaseSearch" :exportFileType="fileType"
        :formateddata="$tera.project.prisma.formatedUpdateDatabaseSearch" :isTable="styleTable" :showAdd="show"
        @update-cell="changed($event)" @add-column="addColumn($event)" @add-row="addRow($event)"
        @delete-row="deleteRow($event)" @delete-column="deleteColumn($event)" />
</template>


<script>
import CustomTable from 'iebh-custom-table/src/components/CustomTable';
export default {
    components: {
        CustomTable
    },
    props: {
        fileType: String
    },
    data() {
        return {
            show: false,
            styleTable: false
        };
    },
    methods: {
        changed(event) {
            console.log("Data Changed", event);
            this.$tera.project.prisma.prismatableUpdateDatabaseSearch.rows[event.rowIndex][event.cellIndex].label = event.updatedCell.label;
            const regex = /\(n=([^)]+)\)/g;
            let formattedText = event.updatedCell.label;
            formattedText = formattedText.replace(regex, (match, value) => {
                if (value === 'xxx') {
                    return `<span style="color: red;">${match}</span>`; // Apply red color for 'xxx'
                } else {
                    return `<span style="color: green;">${match}</span>`; // Apply green color for other values
                }
            });
            // console.log("format", this.$tera.project.prisma.formatedUpdateDatabaseSearch, formattedText)
            this.$tera.project.prisma.formatedUpdateDatabaseSearch.rows[event.rowIndex][event.cellIndex].label = formattedText;
        },
        addColumn(event) {
            console.log("Col added", event);
            this.$tera.project.prisma.prismatableUpdateDatabaseSearch.headers.splice(event.index + 2, 0, event.columnName);
            this.$tera.project.prisma.prismatableUpdateDatabaseSearch.rows.forEach(row => row.splice(event.index + 2, 0, { label: ' ', addClass: [] }));
            this.$tera.project.prisma.formatedUpdateDatabaseSearch.headers.splice(event.index + 2, 0, event.columnName);
            this.$tera.project.prisma.formatedUpdateDatabaseSearch.rows.forEach(row => row.splice(event.index + 2, 0, { label: ' ', addClass: [] }));
        },
        addRow(event) {
            console.log("Row add", event);
            const newRow = [];
            const numColumns = this.$tera.project.prisma.prismatableUpdateDatabaseSearch.rows[0].length;
            for (let i = 0; i < numColumns; i++) {
                newRow.push({ label: ' ', addClass: [] });
            }
            this.$tera.project.prisma.prismatableUpdateDatabaseSearch.rows.splice(event.index + 1, 0, newRow);
            this.$tera.project.prisma.formatedUpdateDatabaseSearch.rows.splice(event.index + 1, 0, newRow);
        },
        deleteRow(event) {
            console.log("Delete Row", event);
            this.$tera.project.prisma.prismatableUpdateDatabaseSearch.rows.splice(event, 1);
            this.$tera.project.prisma.formatedUpdateDatabaseSearch.rows.splice(event, 1);
        },
        deleteColumn(event) {
            console.log("Delete Col", event);
            this.$tera.project.prisma.prismatableUpdateDatabaseSearch.headers.splice(event, 1);
            this.$tera.project.prisma.prismatableUpdateDatabaseSearch.rows.forEach(row => { row.addClass = []; row.splice(event, 1) });
            this.$tera.project.prisma.formatedUpdateDatabaseSearch.headers.splice(event, 1);
            this.$tera.project.prisma.formatedUpdateDatabaseSearch.rows.forEach(row => { row.addClass = []; row.splice(event, 1) });
        }
    }
};
</script>

<style scoped></style>