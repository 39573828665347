/* global globalThis */

import { createApp } from 'vue'

import App from './App.vue'
let app = globalThis.app = createApp(App);

// Import $tera / @iebh/Tera-fy global service
// NOTE: See bottom of file inside main async() init loop for when TeraFy actually boots
import TeraFy from '@iebh/tera-fy';
import TerafyVue from '@iebh/tera-fy/plugins/vue3';
import { defaultProjectState } from './defaultProjectState';

let terafy = new TeraFy()
	.set("devMode", process.env.VUE_APP_TERAFY_DEV)
	.setIfDev("verbosity", process.env.VUE_APP_TERAFY_VERBOSITY)
	.use(TerafyVue); // Add the Vue plugin


// Boot Vue + Tera
(async () => {
	// Boot teraFy + require project + pull & subscribe to project data
	await terafy.init();

	// Ensure there is an active project
	await terafy.requireProject();

	// Wait for state / project list to finalize
	// await terafy.statePromise();

	app.use(terafy.vuePlugin({
		globalName: '$tera', // Install as vm.$tera into every component
	}));

	// Set the default state for this project
	await terafy.setProjectStateDefaults('prisma', defaultProjectState);
	console.log("prisma here", terafy.project.prisma);
	// Mount + go
	app.mount("#app");
})();
